'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getCookie } from 'modules/cookie/getCookie';
import { AppConfigService } from 'modules/appConfig/AppConfigService';
import useAnalytics from 'modules/stores/analytics/useAnalytics';
import { clientTokenName } from 'modules/session/constants/clientTokenName';
import { EAdvertiserType } from 'modules/api/clients/advertisement/detail/enums/advertiser/EAdvertiserType';
import { useAdvertisementStore } from 'modules/stores/page/advertisement/useAdvertisementStore';
import { gtmPathToContentType } from 'modules/stores/analytics/gtmContentTypes';
import { useCookieBarStore } from 'modules/stores/cookieBar/useCookieBarStore';
import { useSegmentParams } from 'modules/segmentParams/useSegmentParams';

interface IUrl {
    pathname: string;
    asPath: string;
    params: Record<string, string>;
}

const serializeUrl = (url: IUrl): string => {
    return JSON.stringify(url);
};

const unserializeUrl = (serializedUrl: string): IUrl => {
    return JSON.parse(serializedUrl);
};

export const usePageViewEvent = (propSearchParams?: Record<string, string>): void => {
    const hookSegmentParams = useSegmentParams();
    const hookSearchParams = useSearchParams();

    const searchParams = useMemo(() => {
        return propSearchParams
            ? propSearchParams
            : hookSearchParams
                ? {
                    ...hookSegmentParams,
                    ...Object.fromEntries(hookSearchParams),
                }
                : {};
    }, [propSearchParams, hookSearchParams, hookSegmentParams]);

    const pathname = usePathname();
    const [isPushStateSended, setIsPushStateSended] = useState(false);
    const isAllowed = useCookieBarStore((store) => store.isAllowed);
    const advDetailState = useAdvertisementStore((store) => store.pageViewAdvertisementDetailState);
    // const { isConsentUpdated } = useDataLayerConsentTracking();

    const {
        gtm: { sendEvent: gtmSendEvent }
    } = useAnalytics();

    const urlSerialized = useMemo(() => {
        const pathnameDefault = pathname || '';

        return serializeUrl({
            pathname: pathnameDefault,
            asPath: pathnameDefault,
            params: searchParams,
        });
    }, [pathname, searchParams]);

    useEffect(() => {
        const { pathname } = unserializeUrl(urlSerialized);

        if (pathname?.startsWith('/vysledky')) {
            fetch(`/api/v2/sitemap?sitemapFilename=vv|${window.location.href}`);
        }
    }, [urlSerialized]);

    const getContentType = useCallback((): string => {
        let contentType = gtmPathToContentType[pathname];
        Object.entries(gtmPathToContentType).forEach((path => {
            if (pathname.startsWith(path[0])) {
                contentType = path[1];
            }
        }));

        return contentType;
    }, [pathname]);


    useEffect(() => {
        if (!urlSerialized) {
            return;
        }

        const { asPath } = unserializeUrl(urlSerialized);

        gtmSendEvent({
            event: 'gtm.historyChange-v2',
            'gtm.historyChangeSource': 'pushState',
            'gtm.newUrl': `${AppConfigService.getNehnutelnostiUrl()}${asPath}`,
        });

        setIsPushStateSended(true);
    }, [urlSerialized, gtmSendEvent]);

    useEffect(() => {
        if (!urlSerialized) {
            return;
        }

        const { params: unserializedParams, pathname } = unserializeUrl(urlSerialized);
        const isAdvertiserLoggedIn = !!getCookie(clientTokenName);
        const contentType = getContentType();

        if (contentType || pathname.startsWith('/detail')) {
            if (typeof window['dataLayer'] === 'undefined') {
                window['dataLayer'] = [];
            }

            const eventData = {
                event: 'page_view_spa',
                content_type: contentType,
                page_brand: AppConfigService.getTitle(),
                page_country_code: 'SK',
                page_language: 'sk',
                is_loggedin: isAdvertiserLoggedIn ? 'true' : 'undefined',
            };

            if (pathname.startsWith('/detail')) {
                if (!advDetailState?.advertiserType) {
                    return;
                }

                eventData.content_type = 'classified listing';

                let pageItemType = 'classified agency listing';

                if (unserializedParams?.['group']) {
                    pageItemType = 'classified agency gallery listing';
                    if (advDetailState?.advertiserType === EAdvertiserType.PRIVATE_PERSON) {
                        pageItemType = 'classified private gallery listing';
                    }
                } else {
                    if (advDetailState?.advertiserType === EAdvertiserType.PRIVATE_PERSON) {
                        pageItemType = 'classified private listing';
                    }
                }

                eventData['page_item_type'] = pageItemType;
            }

            if (isPushStateSended && isAllowed.googleTagManager) {
                gtmSendEvent(eventData);
            }
        }
    }, [
        advDetailState?.advertiserType,
        urlSerialized,
        isPushStateSended,
        gtmSendEvent,
        isAllowed.googleTagManager,
        getContentType,
    ]);
};