'use client';

import { FC } from 'react';
import { Box } from '@mui/material';
import { IBreadcrumb } from 'components/breadcrumbs/interfaces/IBreadcrumb';
import { lemmatizeWordAccordingToNumber } from 'modules/lemmatize/lemmatizeWordAccordingToNumber';
import { Breadcrumbs } from 'components/page/advertisement/listing/components/listing/partials/listingHeader/partials/breadcrumbs/Breadcrumbs';
import { IInitialSearchFilterData } from 'modules/api/clients/advertisement/filter/interfaces/partials/initialFilter/IInitialSearchFilterData';
import { Title as BoxTitle } from 'components/page/advertisement/listing/components/listing/partials/listingHeader/partials/title/common/title/Title';
import { TotalCountTitle } from 'components/page/advertisement/listing/components/listing/partials/listingHeader/partials/title/common/totalCountTitle/TotalCountTitle';
import {
    IAdvertisementListingFilterPagination
} from 'modules/api/clients/advertisement/filter/interfaces/partials/pagination/IAdvertisementListingFilterPagination';
import { OrderBySection } from 'components/page/advertisement/listing/components/listing/partials/listingHeader/partials/orderBy/OrderBySection';

interface IListingHeader {
    totalCount: number;
    searchTitle: string;
    breadcrumbs: IBreadcrumb[];
    advertisementsCount: number;
    pagination: IAdvertisementListingFilterPagination;
    initialSearchFilterData: IInitialSearchFilterData;
}

export const ListingHeader: FC<IListingHeader> = ({
    totalCount,
    searchTitle,
    breadcrumbs,
    advertisementsCount,
}) => {
    const totalCountText = lemmatizeWordAccordingToNumber(totalCount, {
        eqZero: 'nehnuteľností:',
        eqOne: 'nehnuteľnosť:',
        betweenTwoAndFourInclusive: 'nehnuteľnosti:',
        moreThenFour: 'nehnuteľností:',
    });

    return (
        <>
            <Breadcrumbs
                items={breadcrumbs}
            />

            <Box display='flex' justifyContent='space-between'>
                <Box
                    display='flex'
                    gap={{ xs: 0, md: 1 }}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    alignItems={{ xs: 'flex-start' }}
                >
                    <Box flexShrink={0}>
                        <TotalCountTitle text={totalCountText} />
                    </Box>
                    <Box maxWidth={670}>
                        <BoxTitle text={searchTitle} />
                    </Box>
                </Box>

                <OrderBySection
                    advertisementsCount={advertisementsCount}
                />
            </Box>
        </>
    );
};
